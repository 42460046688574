import { mapActions, mapGetters } from 'vuex'
import validation from '@/mixins/validation'
import mixins from '@/mixins'
export default {
  name: 'setting-translation-filter',
  mixin: [mixins, validation],
  data () {
    return {
      filter: {
        locales: [],
        keywords: ''
        // groups: ''
      }
    }
  },
  // watch: {
  //   groups () {
  //     this.filter.groups = this.groups[0].key
  //   }
  // },
  computed: {
    ...mapGetters({
      basicLocales: 'setting/basicLocales',
      basicLocalesLoading: 'setting/basicLocalesLoading',
      groups: 'translations/groups'
    })
  },
  created () {
    // if (this.groups.length) {
    //   this.filter.groups = this.groups[0].key
    // }
    if (!this.basicLocales.length) this.fetchBasicLocales()
  },
  methods: {
    ...mapActions({
      fetchBasicLocales: 'setting/GET_BASIC_LOCALES'
    }),
    changeFilter () {
      this.$emit('update-filter', this.filter)
    }
  }
}
