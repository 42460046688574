import { mapActions, mapGetters, mapMutations } from 'vuex'
import translationFilter from './components/filter/index.vue'
import MainEditor from '@/components/editor/index.vue'
import { debounce } from 'lodash'
export default {
  name: 'setting-translations',
  data () {
    return {
      page: 1,
      filter: {
        keywords: '',
        locales: '',
        groups: ''
      }
    }
  },
  components: {
    translationFilter,
    MainEditor
  },
  created () {
    if (!this.groups.length) {
      this.fetchGroups().then(() => {
        // this.filter.groups = 'labels'
        this.filter.groups = this.$route.params.group
        this.fetchList(this.filter)
      })
    } else {
      // this.filter.groups = 'labels'
      this.filter.groups = this.$route.params.group
      this.fetchList(this.filter)
    }
  },
  watch: {
    currentGroup (newVal) {
      this.filter.groups = newVal
      this.fetchList(this.filter)
    }
  },
  computed: {
    ...mapGetters({
      groups: 'translations/groups',
      groupsLoading: 'translations/isLoading',

      list: 'translationsLabels/list',
      listLength: 'translationsLabels/listLength',
      limit: 'translationsLabels/limit',
      isListLoading: 'translationsLabels/isListLoading'
    }),
    headers () {
      if (!this.list.length) return []
      const list = this.list[0][Object.keys(this.list[0])].map(e => e.locale)
      list.unshift('labels')
      return list
    },
    currentGroup() {
      return this.$route.params.group
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'translationsLabels/GET_LIST',
      update: 'translationsLabels/UPDATE',

      fetchGroups: 'translations/GET_GROUPS'
    }),
    ...mapMutations({
      changeSkip: 'translationsLabels/CHANGE_SKIP'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList(this.filter)
    },
    sendRequest: debounce(function () {
      this.fetchList(this.filter)
    }, 1000),
    updateFilter (data) {
      this.changeSkip(0)
      Object.assign(this.filter, data)
      this.sendRequest()
    },
    updateLabel (value, item) {
      const formData = new FormData()
      for (const i in item) {
        if (i !== 'value') {
          formData.append(i, item[i])
        }
      }
      formData.append('value', value)
      if (value !== item.value && value !== '') {
        this.update(formData).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      }
    }
  },
  destroyed () {
    this.changeSkip(0)
  }
}
